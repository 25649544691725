@import "../../common/scss-setup/index";

.start-new-test {
  background-color: $background-color;
  padding: 1rem;

  .header {
    text-align: center;
    margin-bottom: 20px;
  }

  .pagination-buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;

    .pagination-button {
      margin: 0 5px;
      padding: 6px 10px;
      font-size: 16px;

      background-color: white;
      color: $dark-green;
      border-color: $dark-green;

      &:hover {
        background-color: $medium-green;
        border-color: $dark-green;
      }

      &.selected {
        background-color: $medium-green;
        border-color: $dark-green;
        color: white;
      }
    }
  }

  .button {
    display: flex;
    justify-content: center;

    .button-go-test {
      padding: 10px 20px;
      font-size: 18px;
    }
  }

  .picture {
    padding: 3rem 0;
    width: 100%;
    .icon {
      color: $light-green;
      height: 8rem;
    }
  }
  .button {
    padding-top: 20px;

    .button-go-test {
      width: 213px;
      height: 43px;
      padding: 0 5px;
      border: none;
      font-size: 16px;
      background-color: $medium-green;

      &:hover {
        background-color: $dark-green;
        border-color: $dark-green;
      }
    }
  }
}

.oled-row {
  display: flex;
  justify-content: center;
}
