@import "../../common/scss-setup/index";

.success-test {
  background-color: #ffffff;
  padding: 1rem;
  height: calc(100vh - 60px);
}

.picture {
  width: 85%;
}

@media only screen and (min-width: 576px) {
  .picture {
    width: 40%;
  }
}

.buttons {
  padding-top: 2rem;

  button.btn-result {
    width: 80%;
    margin-bottom: 10px;
    height: 43px;
    padding: 0 5px;
    border: 1px solid $medium-green;
    font-size: 16px;
    color: #ffffff;
    background: $medium-green;

    &:hover {
      background-color: $dark-green;
      border-color: $dark-green;
      color: #ffffff;
    }
  }
  button.btn-test {
    width: 80%;
    height: 43px;
    padding: 0 5px;
    border: 1px solid $medium-green;
    font-size: 16px;
    color: $medium-green;
    background: #ffffff;

    &:hover {
      background-color: $dark-green;
      border-color: $dark-green;
      color: white;
    }
  }
}
