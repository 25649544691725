@import "../../common/scss-setup/index";

.list-item {
  padding-left: 0.5rem;
  width: 100%;

  .description {
    .icons {
      display: flex;
      width: 100%;
      color: $gray-text;

      .tag {
        width: auto;
        padding: 0.25rem;
      }

      .comment {
        width: auto;
        padding: 0.25rem;
      }
    }
  }

  .deviceContainer {
    margin-left: -3px;
    display: flex;
    padding-left: 0;
    justify-content: start;
    white-space: break-spaces;
    word-break: break-all;
  }

  .delete-icon {
    cursor: pointer;
  }

  .icon {
    display: flex;
    justify-content: right;
    align-items: center;
  }
}
