@import "../../common/scss-setup/index";

.results-list-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 170px);

  .list {
    overflow-y: auto;
  }
}
