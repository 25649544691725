@import "../../common/scss-setup/index";

.results-to-send {
  color: $dark-green-text;
  border: 1px solid $medium-green;
  border-radius: 4px;
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  overflow: hidden;

  .icon {
    height: 2rem;
    padding: 0.5rem;
  }

  .text {
    padding-top: 0.25rem;
    padding-left: 0.25rem;
    align-items: center;
    text-align: left;
  }
}
