@import "../../common/scss-setup/index";

.upload-results-item {
  width: 100%;
  display: flex;
  background-color: white;
  margin-top: 5px;
  padding-left: 15px;
  padding-right: 15px;
  justify-content: center;

  .text {
    .title {
      color: #69b70f;
      width: 100%;
      text-align: justify;
      font-size: 18px;
    }

    .description {
      display: flex;
      width: 100%;
      color: $gray-text;
      text-align: justify;
      font-size: 12px;
    }
  }
  .upload-icon {
    color: #4a800b;
    padding-right: 0.5rem;
    padding-top: 0.5rem;
    font-size: 25px;
  }

  &:hover {
    cursor: pointer;
  }
}
