@import "../../common/scss-setup/index";

.button {
  .cancel-button {
    color: $dark-green-text;
    background-color: $background-color;
    border-color: $medium-green;
    width: 80vw;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    &:hover {
      background-color: $dark-green !important;
      border-color: $dark-green !important;
    }
  }
}
