@import "../../common/scss-setup/index";

.home-page {
  background-color: #ffffff;
  height: calc(100vh - 60px);
  font-size: $enormous-font-size;
  color: $light-green;
  text-decoration: none solid;
  font-weight: bold;

  .logo {
    padding-top: 90px;
  }

  .logo-img {
    width: 85%;
  }

  @media only screen and (min-width: 576px) {
    .logo-img {
      width: 40%;
    }
    .button {
      width: 30%;
      margin: auto;
    }
  }

  .button {
    padding-top: 54px;

    button {
      width: 85%;
      height: 32px;
      padding: 0 5px;
      border: none;
      background-color: $medium-green;

      &:hover {
        background-color: $dark-green;
      }
    }
  }
}
