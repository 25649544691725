@import "../../common/scss-setup/index";

.site-select {
  color: $dark-green-text;
  border: 1px solid $medium-green;
  border-radius: 4px;
  display: flex;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  overflow: hidden;
  align-items: center !important;

  .icon {
    height: 2rem;
    padding: 0.5rem;
  }

  .text {
    padding-top: 0.8rem;
    padding-left: 0.25rem;
    text-align: left;
  }

  .button-select-site {
    color: $dark-green-text;
    background-color: white;
    border-color: $medium-green;
    width: 100%;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;

    &:hover {
      background-color: $dark-green !important;
      border-color: $dark-green !important;
    }
  }
}
