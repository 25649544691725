@import "../../common/scss-setup/index";

.error-page {
  background-color: #ffffff;
  height: calc(100vh - 60px);
  text-decoration: none solid;
  font-weight: bold;

  .picture {
    width: 100%;
  }

  .button {
    padding-top: 20px;

    .button-go-home {
      width: 213px;
      height: 43px;
      padding: 0 5px;
      border: none;
      font-size: 16px;
      background-color: $medium-green;

      &:hover {
        background-color: $dark-green !important;
        border-color: $dark-green !important;
      }
    }
  }
}
