@import "../../common/scss-setup/index";

.modal-test-details {
  font-family: Verdana, sans-serif;
  margin: auto;

  .btn-close {
    display: none;
  }

  .modal-dialog {
    width: 90vw;
    margin: auto;
    padding-top: 1rem;
  }

  .header {
    color: $light-green;
    border-bottom: none;
    align-content: center;
    justify-content: center;
    width: 100%;
    border-bottom: 1px solid $light-green;
  }

  .body .item {
    border: none;
  }

  .body .form-text {
    font-size: $tiny-font-size;
  }

  .body .form-label {
    font-size: $tiny-font-size;
    color: $medium-gray;
  }

  .record-form {
    padding-top: 1rem;
  }

  .footer {
    border-top: none;
    justify-content: left;
  }

  .body .btn-modal-save {
    width: 82px;
    height: 32px;
    margin-right: 12px;
    background-color: $medium-green;
    border-color: $medium-green;

    padding: 0;

    &:hover {
      background-color: $dark-green;
      border-color: $dark-green;
    }
  }

  .body .btn-modal-cancel {
    width: 82px;
    height: 32px;
    margin-right: 12px;
    background-color: #ffff;
    color: $dark-green;
    border-color: $dark-green;

    padding: 0;

    &:hover {
      background-color: $dark-green;
      border-color: $dark-green;
      color: #ffff;
    }
  }

  .tags-input {
    font-family: Verdana, sans-serif;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    align-content: center;
  }

  .tag-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    min-height: 30px;
    border: 1px solid $medium-gray;
    border-radius: 5px;
    padding: 10px;
  }

  .tag {
    border: none;
    height: 1rem;
    color: $medium-green;
  }

  .comment {
    height: 1rem;
    color: $medium-green;
  }

  .tag-container input {
    border: none;
    flex: 1;
    outline: none;
    background: #ffffff;
    color: #000000;
    width: 30px;
  }

  .tag span {
    margin-left: 5px;
    cursor: pointer;
  }
}
