@import "../../common/scss-setup/index";

.results {
  background-color: $background-color;

  .header {
    font-size: $big-font-size;
    color: $green-text;
    padding: 0;
    border-bottom: 1px solid $dark-green;

    .upload-icon {
      padding-right: 0.5rem;
      padding-top: 0.5rem;
      float: right;
    }
  }

  h1 {
    font-size: $big-font-size;
    color: $green-text;
    padding: 0;
  }
}
