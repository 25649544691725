@import "../../common/scss-setup/index";

.success-page {
  background-color: #ffffff;
  height: calc(100vh - 60px);
  text-decoration: none solid;
  font-weight: bold;
}

.picture {
  width: 85%;
}

@media only screen and (min-width: 576px) {
  .picture {
    width: 40%;
  }
}
