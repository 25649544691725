@import "../common/scss-setup/index";

.header {
  color: $green-text;
  padding-top: 1.5rem;
}

.subheader {
  color: $green-text;
  font-size: $small-font-size;
}
