@import "../../common/scss-setup/index";

.bot-nav {
  position: fixed;
  bottom: 0;
  width: 100%;

  .bottom-navigation {
    background-color: white;
    height: 60px;
    padding: 0;
    justify-content: center;

    a.nav-link:hover,
    a.nav-link:active {
      background: $medium-green;

      .icon-logo {
        color: $hover-dirty-white;
      }
    }

    a.nav-link.disabled {
      opacity: 0.3;
      color: gray;
    }

    a.fon.nav-link {
      font-size: $small-font-size;
    }

    .icon-logo {
      font-size: $big-font-size;
      color: $medium-green;
      height: 40px;

      &:hover {
        color: $hover-dirty-white;
      }
    }

    .navbar-nav {
      display: block;
    }

    .navbar-dark .navbar-nav .login-logout-container .nav-link {
      display: initial;
      color: $dark-green;
      padding: 0;

      &:hover {
        color: $hover-dirty-white;
      }
    }

    .home-page-container,
    .tests-container,
    .results-container {
      width: 100%;
      border-top: 1px solid $dark-green;
      border-right: 1px solid $dark-green;
    }

    .home-page-container.active,
    .results-container.active,
    .tests-container.active {
      background: $medium-green;

      .icon-logo {
        color: $hover-dirty-white;
      }
    }

    .results-container {
      border-left: 1px solid $dark-green;

      .button-with-notification {
        position: relative;

        .notification {
          position: absolute;
          left: 50%;
          top: 0;
          margin-left: 18px;
          width: 18px;
          height: 14px;
          border-radius: 30px;
          color: white;
          margin-top: -2px;
          font-size: $tiny-font-size;
          line-height: $smaller-font-size;
          background: $red-button;
        }
      }

      &.active {
        .icon-logo {
          color: $hover-dirty-white;
        }
      }
    }
  }
}
