@import "../../common/scss-setup/index";

.modal-logout .body .btn-modal-save {
  width: 82px;
  height: 32px;
  margin-right: 12px;
  background-color: $medium-green;
  border-color: $medium-green;

  padding: 0;

  &:hover {
    background-color: $dark-green;
    border-color: $dark-green;
  }
}

.modal-logout .body .btn-modal-cancel {
  width: 82px;
  height: 32px;
  margin-right: 12px;
  background-color: #ffff;
  color: $dark-green;
  border-color: $dark-green;

  padding: 0;

  &:hover {
    background-color: $dark-green;
    border-color: $dark-green;
    color: #ffff;
  }
}
