@import "../../common/scss-setup/index";

.test-in-progress {
  background-color: $background-color;
  padding: 1rem;
  height: 100vh;

  .picture {
    padding: 3rem 0;
    width: 100%;
    .icon {
      color: $light-green;
      height: 5rem;
    }
  }
  .button {
    padding-top: 20px;

    .button-finish {
      color: $dark-green-text;
      width: 213px;
      height: 43px;
      padding: 0 5px;
      border-color: $dark-green;
      font-size: 16px;
      background-color: white;

      &:hover {
        background-color: $dark-green !important;
        border-color: $dark-green !important;
      }
    }
  }
}
